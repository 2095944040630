.error-div {
  padding: 1rem;
  margin-top: 2rem;
  width: 50%;
  position: relative;
  left: 25%;
  background-color: #ffdde1;
}

.local-credentials-div {
  padding: 1rem;
  margin-top: 2rem;
  width: 50%;
  position: relative;
  left: 25%;
  background-color: #ddfaff;
}

.error-div-padding-top {
  padding-top: 1rem;
}


.App {
  text-align: center;
}

html, body, #root, #root>div {
  height: 100%
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav a {
  color:white;
  margin: 0 10px;
}

.main-content{
   /* The image used */
   background-image:url(./images/banner.jpeg);

   /* Full height */
   height: 100%; 
   width: 100%;

   /* Center and scale the image nicely */
   background-position: center;
   background-repeat: no-repeat;
   background-size: 100% auto;
   
   overflow: scroll;
}

.card{
  margin: 50px 0;
}

.card-header {
  text-align: center;
}

.layer {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}