.facets {
  padding: 0 2rem;
  width: 80%;

  * {
    font-size: 14px;
    word-break: break-all;
  }

  .facet-panel-header {
    padding-left: 2rem;
    padding-bottom: 2rem;

    cursor: pointer;

    .facet-panel-icon {
      display: inline-block;
      position: relative;
      padding-right: 0.5rem;
    }

    .facet-panel-expand-icon {
      display: inline-block;
      position: relative;
      padding-left: 1rem;
      width: 2rem;
      min-height: 1px;
    }
  }
}
