.facet {
  padding-bottom: 1rem;

  .facet-value-container {
    padding-left: 1rem;

    .facet-checkbox {
      padding-top: 0.5rem;
    }
  }

  .facet-footer {
    padding-left: 1rem;
    padding-top: 0.5rem;
  }
}
