@import "../../constants.scss";

$slider-size: 6px !default;
$slider-thumb-size: 15px !default;
$slider-thumb-blur: 2px !default;
$slider-thumb-spread: 1px !default;
$slider-thumb-focus-spread: 3px !default;

.facet {
  .facet-date-container {
    padding: 0 0.5rem;

    /* Fix tooltip flicker */
    .google-visualization-tooltip {
        pointer-events: none;
    }

    /* Prevent tooltip text wrapping */
    .google-visualization-tooltip-item {
      white-space: nowrap;
    }
  }

  .facet-date-label-left {
    display: inline-block;
  }

  .facet-date-label-right {
    float: right;
  }

  .rc-slider {
    display: inline-block;
    width: 100%;

    &.with-number-input {
      width: calc(95% - 6rem);
      margin-right: 1.5rem;
    }

    .rc-slider-rail {
      position: absolute;
      height: $slider-size;
      border-radius: 5px;
      background-color: $grey;
      opacity: 0.7;
      transition: opacity 0.2s;
    }

    .rc-slider-track {
      position: absolute;
      height: $slider-size;
      border-radius: 5px;
      background-color: $blue;
    }

    .rc-slider-handle {
      width: $slider-thumb-size;
      height: $slider-thumb-size;
      border-radius: 50%;
      background: white;
      border: none;

      box-shadow: 0 0 $slider-thumb-blur $slider-thumb-spread $border-color;

      &:focus {
        box-shadow: 0 0 $slider-thumb-blur $slider-thumb-focus-spread
          $border-color;
      }
    }

    .rc-slider-dot {
      height: $slider-size;
      width: $slider-size;
      bottom: -2px;
      margin-left: -2px;
      box-shadow: 0 0 $slider-thumb-blur $slider-thumb-spread $border-color;
      border: none;
    }

    .rc-slider-mark-text {
      color: $grey;

      font-size: 1.2rem;
      line-height: 1.2rem;

      transform: translateX(0.5rem) !important;
      white-space: nowrap;

      &.rc-slider-mark-text-active {
        color: $dark-grey;
      }
    }
  }
}
